import React from 'react'

const Tag = ({text,onClick = () => 1,radius = 0}) => {
  return (
<div  onClick = {onClick} style = {{padding:4,color:'var(--text-color)',boxShadow:'var(--shadow)',borderRadius:radius,margin:'4px 4px',background:'var(--tag-color)'}}>
<p  style = {{margin:0,fontWeight:'bold',fontSize:12}}>{text}</p>
</div>
  )

}
export default Tag;