
import {db} from '../firebase-config'
import uuid from 'uuid/v1'







const actionsType = {
  loadDocs:'load-docs',
  loadDocsSuccee:'load-docs-succee',
  docUpdated:'doc-updated'
  
}


const docs = JSON.parse(localStorage.getItem('nawaa-docs')) || []

const initState = {
  docs,
  fetchDocs:false
} //JSON.parse(localStorage.getItem('BMC')) || createBMCTemp('my BMC')


export const dashboardActions = {
  loadUserDocs(user){
    return dispatch => {
      dispatch({type:actionsType.loadDocs})
      db.collection('docs')
      .where('user','==',user)
      .onSnapshot(snap => {
        //console.log(snap.docChanges())
        const docs = snap.docs.map(d => d.data())
        .sort((a,b) => new Date(a.createAt.seconds) - new Date(b.createAt.seconds))

        localStorage.setItem('nawaa-docs',JSON.stringify(docs))
       
        var source = snap.metadata.fromCache ? "local cache" : "server";
        dispatch({type:actionsType.loadDocsSuccee,payload:{docs}})
        //console.log("Data came from " + source)
       
      })
      
    }
  },
  
  
}

const strategy = {
  [actionsType.loadDocsSuccee]: (state,{docs}) => {
    return {...state,docs,fetchDocs:true}
  }
}



export function dashboardReducer(state = initState,action) {
return strategy[action.type] ? strategy[action.type](state,action.payload) : state
}