import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'

const ErrorMessage = ({message}) => {
  return(
    <div style = {{display:'flex',alignItems:'center'}}>
      <FontAwesomeIcon icon = {faTimesCircle}/>
      <p style = {{margin:0,padding:4}}>{message}</p>
    </div>
  )
}
const TextInput = ({type = 'text',focus = false,value = '',onTextChange,placeholder = 'type something',onEnter,validator = ()=> undefined,errors = [],onValidate = ()=>1,delay = 500,label}) => {
  let diraction = placeholder.match(/^[\u0621-\u064A\u0660-\u0669 ]+$/) ? 'rtl' : 'ltr'
  let timeout = 0
  const handle = e => {
    const inputValue = e.target.value.trim()
    const error = validator(inputValue)
    if(timeout) {
      clearTimeout(timeout)
    }
    if(error) {
      
      timeout = setTimeout(()=>{
        onValidate(error)
      },delay)
      
    }
    if(e.keyCode === 13 && onEnter) {
      // ignore white space
      if(inputValue === '' || inputValue.match(/$\s/)) {
        e.target.value = ''
        document.activeElement.blur()
        return
      }
      onEnter(inputValue)
      e.target.value = ''
      return
    }
    if(onTextChange) {
      onTextChange(inputValue)
    }
  }
return(
  <div >
    <h3 dir = 'auto' style = {{margin:'0 0 8px 0'}}>{label}</h3>
  <div style = {{display:'flex',alignItems:'center',justifyContent:'center'}}>
  <input type = {type} autoFocus = {focus} onFocus = {e => e.target.scrollIntoView()} dir = 'auto' style = {{width:'100%',padding:'8px 16px'}}  defaultValue = {value} onKeyUp = {handle} placeholder = {placeholder}/>
  </div>
  <div dir = 'auto'>
  {errors.map((e,i) => <ErrorMessage message = {e.message} key = {i}/>)}
  </div>
   
  </div>
)
}
export default TextInput