
const actionsType = {
  toggleToolBar:'toggle-tool-bar',
  changeTitle:'change-title',
  exportCanvas:'export-canvas',
}

const initState = {
  show:false,
  title:'nawaa'
}


export const toolBarActions = {
  changeTitle(title){
    return {type:actionsType.changeTitle,payload:{title}}
  },
  
}

const strategy = {
  [actionsType.changeTitle]: (state,{title}) => {
    return {...state,title}
  }
}


export function toolBarReducer(state = initState,action) {
return strategy[action.type] ? strategy[action.type](state,action.payload) : state
}