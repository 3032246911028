
export const makeVaildate = function() {
  const funcs = Array.prototype.slice.call(arguments)
  
  return function(val) {
    return funcs.reduce((p,c) => {
      const e = c(val)
      if(e){
        p.push(e)
      }
      return p
    },[])
  }
}
export  function  makeChcker(message, condition) {
       
  return  condition ? new Error(message) : undefined
}

const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const usernameRegx = /^[a-zA-Z0-9]*$/
export const makeNotEmpty = message => val => makeChcker(message,val.length === 0) 
export const makeMinLength =  (message,min) => val => makeChcker(message,val.length < min)
export const makeIsEmail = message => val => makeChcker(message,!val.match(emailRegx))
export const makeNotNumber = message => val => makeChcker(message,!isNaN(val)) 
export const makeNoWhiteSpace = message => val => makeChcker(message,!val.match(usernameRegx))
