
import {auth,db} from '../firebase-config'
import {canvseActions} from '../canvse/canvas-redux'
import uuid from 'uuid/v1'
import * as analysis from '../analysis'

 const actionsType = {
  loginRequest:'login-request',
  loginSuccess:'login-success',
  loginFailure:'login-failure',
  singupRequest:'singup-request',
  singupSuccess:'singup-success',
  singupFailure:'singup-failure',
  logout:'logout',
  userUpdated:'user-updated',
  onAuth:'on-auth',
  resetErorrs:'reset-erorrs',
  sendRestPassword:'send-rest-password',
  restPasswordFailure:'rest-password-failure'
 
}

export const authActions = {
  login,
  singup,
  logout,
  updateUser,
  onAuth,
  resetErorrs,
  restPassword
}

let user = JSON.parse(localStorage.getItem('user'))

if(user && !user.workspaces) {
  user = null
  //canvseActions.loadData(user.id)
}
if(user) {
  analysis.userLogin(user.id)
}
const initState =  {
  user,
  authErrors:[],
  resetPassword:false
}

const strategy = {
  [actionsType.loginSuccess]: (state,{user}) => {
    return {...state,user,authErrors:[],resetPassword:false}
  },
  [actionsType.singupSuccess]: (state,{user}) => {
    return {...state,user,authErrors:[]}
  },
  [actionsType.logout]: (state) => {
    return {...state,user:null,authErrors:[]}
  },
  [actionsType.userUpdated]: (state,{user}) => {
    return {...state,user}
  },
  [actionsType.loginFailure]: (state,{erorr}) => {
    return {...state,authErrors:[erorr]}
  },
  [actionsType.singupFailure]: (state,{erorr}) => {
    return {...state,authErrors:[erorr]}
  },
  [actionsType.restPasswordFailure]: (state,{erorr}) => {
    return {...state,authErrors:[erorr]}
  },
  [actionsType.resetErorrs]: state => {
    return {...state,authErrors:[]}
  }
}

export function authReducer(state=initState,action){
  return strategy[action.type] ? strategy[action.type](state,action.payload): state
}

 

function login({email,password}){
  return dispatch => {
    dispatch({type:actionsType.loginRequest})
    auth.signInWithEmailAndPassword(email,password)
    .then(firebaseUser => {
      db.doc('users/'+ firebaseUser.user.uid)
      .get()
      .then(uSnap => {
        // user
        const user = uSnap.data()
        db.collection('workspaces')
        .where('owner','==',user.id)
        .get()
        .then(wSnap => {
          // workspace
          user.workspaces = wSnap.docs.map(d => d.data())
          localStorage.setItem('user',JSON.stringify(user))
          analysis.userLogin(user.id)
        dispatch({type:actionsType.singupSuccess,payload:{user}})

        })
        
        // dispatch(canvseActions.loadData(user.id))
      })
    })
    .catch(() => {
      // auth/user-not-found
      // auth/wrong-password
     
      const erorr = new Error('wrongEmailOrPassword')
      dispatch({type:actionsType.loginFailure,payload:{erorr}})
    })
   }

}
function singup({username,email,password,fullName}) {
  return dispatch => {
    dispatch({type:actionsType.singupRequest})
    auth.createUserWithEmailAndPassword(email,password)
       .then(firebaseUser => {
         const workspaceId= uuid()
         const user = {username:username.toLowerCase().trim(),email,id:firebaseUser.user.uid,fullName}
         analysis.setNewUser({id:user.id,username:user.username,email,name:user.fullName})
         localStorage.setItem('user',JSON.stringify(user))
         db.doc('users/'+user.id).set(user)
         db.doc('workspaces/'+workspaceId).set({id:workspaceId,name:`${user.username} workspace`,type:'personal',pro:false,owner:user.id,members:[]})
        //  dispatch(canvseActions.createDoc(user.id,workspaceId,()=>{
          dispatch({type:actionsType.singupSuccess,payload:{user}})
          // clean up later
        //  }))
         
       })
       .catch(error => {
         // email-already-in-use
         const erorr = new Error('emailAlreadyUsed')
      dispatch({type:actionsType.singupFailure,payload:{erorr}})
       })
  }
}

function onAuth() {
  return dispatch => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        // dispatch(canvseActions.loadData(user.uid))
      } else {
        // No user is signed in.
        localStorage.removeItem('user')
        // localStorage.removeItem('BMC')
      }
    });
  }
  
}

function logout() {
  auth.signOut()
  return {type:actionsType.logout}
}

function restPassword(email) {
  return dispatch => {

    auth.sendPasswordResetEmail(email).then(function() {
      // Email sent.
      dispatch({type:actionsType.sendRestPassword})
    }).catch(function(error) {
      const erorr = new Error('emailNotFound')
      dispatch({type:actionsType.restPasswordFailure,payload:{erorr}})
      // An error happened.
    });
  }
  
}

function updateUser(user) {
  return {type:actionsType.userUpdated,payload:{user}}
}

function resetErorrs() {
  return {type:actionsType.resetErorrs,payload:{}}
}