import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {toolBarActions} from '../tool-bar/tool-bar-redux'
import {dashboardActions} from './dashboard-redux'
import DateCard from '../components/date-card'
import {canvseActions} from '../canvse/canvas-redux'
import MainToolBar from '../components/main-tool-bar'
import Page from '../components/page'


const DocCard = ({doc,onClick = ()=>1}) => {
  
  

  return(
    <div onClick = {onClick} style = {{overflowWrap:'break-word',padding:8,background:'#fff',boxShadow:'var(--shadow)', borderRadius:10,cursor:'pointer'}}>
     <h3 dir = 'auto' style = {{margin:0,textAlign:'center',width:'100%'}}>{doc.name} </h3>
     <DateCard seconds = {doc.createAt.seconds} lable = 'create at:' />
    </div>
  )
}




class Dashboard extends React.Component {
  constructor(props){
    super()
    const {dispatch,user,fetchDocs} = props
    if(fetchDocs === false) {
      dispatch(dashboardActions.loadUserDocs(user.id))
    }
   
  }
  
  componentDidMount(){
    
    const {dispatch} = this.props
   
    
     dispatch(toolBarActions.changeTitle('مساحة العمل'))
   
   
  }
  render() {
    
    const {docs,dispatch,history,user} = this.props
    const createCanvas = () => {
      dispatch(canvseActions.createCanvas(user.id,user.workspaces[0].id))
      history.push('/canvas')
    }
    const goToCanvas = canvas => {
      
      dispatch(canvseActions.selectCanvas(canvas))
      history.push('/canvas')
    }
    return (
      <div >
        <MainToolBar 
        center = {<h2 style = {{margin:0}}>مساحة العمل</h2>}
        rigth = {<p style = {{cursor:'pointer',padding:8,margin:0,fontSize:24,fontWeight:'bold',color:'var(--primary-color)'}} onClick = {()=>  createCanvas()}>+</p>}
        />
        
        <Page>
        <div className = 'gird-list' >
        
        {docs.map((d,i)=> <DocCard onClick ={goToCanvas.bind(null,d)}  key = {i} doc = {d}/>)}
       
        
      </div>
      {/* add it to localization later */}
      {/* <p style = {{padding:4,margin:0,fontSize:18,fontWeight:'bold',color:'var(--primary-color)'}} onClick = {()=>  createCanvas()}>+</p>} */}
      {!docs.length && <p style = {{textAlign:'center'}}>اضفة نموذج عمل<span style = {{padding:4,margin:0,fontSize:18,fontWeight:'bold',color:'var(--primary-color)'}}>+</span> وبداء صناعة حلمك</p>}
          
        </Page>

      </div>
      
  )
  }
}


const stateToProps = state => ({strings:state.language,user:state.auth.user,...state.dashboard})

export default withRouter(connect(stateToProps)(Dashboard))