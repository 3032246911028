import Firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/auth'

const productionConfig = {
  apiKey: "AIzaSyBxaWl87lESf20VENiRSCB2sJB5v-lLRFg",
  authDomain: "nawaa-production.firebaseapp.com",
  databaseURL: "https://nawaa-production.firebaseio.com",
  projectId: "nawaa-production",
  storageBucket: "nawaa-production.appspot.com",
  messagingSenderId: "897980182850"
};
const devConfig = {
  apiKey: "AIzaSyDbQd_sl7IrlP3REHhpFgFQfEDyS61Deks",
  authDomain: "nuaa-ccec0.firebaseapp.com",
  databaseURL: "https://nuaa-ccec0.firebaseio.com",
  projectId: "nuaa-ccec0",
  storageBucket: "nuaa-ccec0.appspot.com",
  messagingSenderId: "611932198536"
}


Firebase.initializeApp(devConfig)
const settings = {timestampsInSnapshots: true}

export const db = Firebase.firestore()
db.settings(settings)

export const auth = Firebase.auth()
