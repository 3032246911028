import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Tag from '../components/tag'
import Placeholder from '../components/placeholder'
import {canvseActions} from './canvas-redux'
import Page from '../components/page'
import MainToolBar from '../components/main-tool-bar'
import Editor from '../components/editor'


const BlockCard = ({name,title,items,navTo,placeholder}) => {
 
  return(
    <div  onClick = {navTo} style = {{cursor:'pointer',background:'var(--element-background-color)',boxShadow:'var(--shadow)',padding:12,minHeight:'75px',borderRadius:5,gridArea:name}}>
      <h3 style = {{textAlign:'center',color:'var(--primary-color)'}}>{title}</h3>
      <div style = {{display:'flex',flexWrap:'wrap',justifyContent:'center',margin:0,padding:0}}>

      { items.map((item,i)=> <Tag key = {i} text = {item.name}/>)}
     
      </div>
    </div>
  )
}




function CanvasOverview ({canvas,history,strings,dispatch}){
  const updateCanvasName = (id,name) => dispatch(canvseActions.updateCanvasProps(id,{name}))
  const tools = (
    <div style = {{display:'flex'}}>
    <p style = {{cursor:'pointer',padding:4,margin:0,fontSize:18,fontWeight:'bold',color:'var(--primary-color)'}} onClick = {()=>  dispatch(canvseActions.removeCanvas(canvas.id))}>حذف</p>
    <p style = {{cursor:'pointer',padding:4,margin:0,fontSize:18,fontWeight:'bold',color:'var(--primary-color)'}} onClick = {()=>  dispatch(canvseActions.exportCanvas(canvas.name))}>{strings.export}</p>
  </div>
  )
  return (
    <div  >
      <MainToolBar backButton 
        center = {<Editor heading inline value = {canvas.name} onSubmit = { updateCanvasName.bind(null,canvas.id)} />}
        rigth = {tools}
        />
        
        <Page>
        <div id = 'bmc'>
    <div className = 'canvas-view'>
    {Object.keys(canvas.blocks).map((k,i) =>{
      
      return <BlockCard name = {k} title = {strings[k]} placeholder = {strings.clickToAddItemsPlaceholder} key = {i} items = {canvas.blocks[k]} navTo = {() => history.push(`/canvas/${k}`)}/>
    })}
    </div>
    
    </div>

        </Page>
   
    
    </div>
  )
}


const stateToProps = state => ({canvas:state.canvas,strings:state.language})

export default withRouter(connect(stateToProps)(CanvasOverview))