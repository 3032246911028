import React from 'react'
import {connect} from 'react-redux'
const Home = ({history,strings}) => {
  return (
<div >
<h2 style = {{textAlign:'center'}}> {strings.landing.titel}</h2>
<div style = {{display:'grid',rowGap:12}}>
   
      <p style = {{textAlign:'center',color: '#32BC43',fontWeight:'bold',fontSize:14,}} onClick = {()=> history.push('/auth/signup')}>{strings.landing.cta}</p>
    </div>
</div>
  )

}
const stateToProps = state => ({strings:state.language})
export default connect(stateToProps)(Home)