import React from 'react'
import {Editor, EditorState,ContentState,RichUtils,getDefaultKeyBinding,convertFromHTML,convertToRaw} from 'draft-js'

export default class RichEditor extends React.Component{
constructor(props) {
  super(props)
 // this is hack fix it later
  const {value,heading} = props

  const content = heading ? convertFromHTML(`<h2>${value ||'untitled'}</h2>`) : convertFromHTML(`<p>${value ||'untitled'}</p>`)
  this.state = {editorState:EditorState.createWithContent(ContentState.createFromBlockArray(content)),
  focus:false}
  
}
componentWillReceiveProps(nextProps){
  
  if(nextProps.value !== this.props.value){
   
    const {heading} = this.props
    const content = heading ? convertFromHTML(`<h2>${nextProps.value ||'untitled'}</h2>`) : convertFromHTML(`<p>${nextProps.value ||'untitled'}</p>`)
    const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(content))
    
      this.setState({editorState});
  }
}
handleKeyCommand = (command) => {
  const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
  if(command === 'inline-done') {
    const text = convertToRaw(this.state.editorState.getCurrentContent())
   
    this.props.onSubmit(text.blocks[0].text)
    this.setState({focus:false})
  }
  if(command === 'blur') {
    
  }
  if (newState) {
    
      this.onChange(newState);
      
      return 'handled';
  }
  return 'not-handled';
}
keyBinding(e){
  const {inline} = this.props || false
  
  if(e.keyCode === 13 && inline) {
    
    document.activeElement.blur()
    return 'inline-done'
  }
  return getDefaultKeyBinding(e)
}
onChange(editorState){
  
this.setState({editorState})
}
onClick(e) {
  
   //e.focus()
  this.setState({focus:true})
}

render(){
  
  const focusStyle = this.state.focus ? {border:'1px solid black'} : {}
  return(
    <div onClick = {this.onClick.bind(this)} style = {{height:'auto',padding:8,...focusStyle,display:'content'}}>
 <Editor style = {{padding:0}} keyBindingFn = {this.keyBinding.bind(this)} editorState = {this.state.editorState} onChange = {this.onChange.bind(this) } handleKeyCommand={this.handleKeyCommand.bind(this)}/>
    </div>
     
   
  )
}

}


// const RichEditor = ({value,inline = false}) => {
//   const content = convertFromHTML(`<h2>${value}</h2>`)
//   let editorState =EditorState.createWithContent(ContentState.createFromBlockArray(content))
//   function keyBinding(e){
  
//     if(e.keyCode === 13 && inline) {
//       document.activeElement.blur()
//       return 'done'
//     }
//     return getDefaultKeyBinding(e)
//    }
//    function onChange(state){
//     editorState = state
//    }
    
// return(
// <div style = {{padding:0,margin:0}}>
//   <Editor keyBindingFn = {keyBinding}
//    editorState = {editorState}
//     onChange = {onChange }
//     //  handleKeyCommand={this.handleKeyCommand.bind(this)}
//      />
// </div>
// )
// }
// export default RichEditor

