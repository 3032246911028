import React from 'react'
import {BrowserRouter,Route,Switch} from 'react-router-dom'

import Header from './components/header'
import {connect} from 'react-redux'
import {Redirect,Link} from 'react-router-dom'
import Auth from './auth'
import {authActions} from './auth/auth-redux'
import Canvas from './canvse'
import ToolBar from './tool-bar'
import Home from './home'
import Dashboard from './dashboard'
import Sandbox from './sandbox'

const Router = ({user,dispatch,strings}) => {
  
  
  return(
    <BrowserRouter >
    <div style = {{display:'flex',flexDirection:'column'}}>
       <AppHeader strings = {strings} user = {user} dispatch = {dispatch}/>
     
        
       
       
          
          <Switch>
          <Route exact path = '/landing' component = {Home}/>
          <Route  path = '/auth' component = {Auth}/>
          <ProtectedRoute isAllowed = {user} exact   path = '/' component = {Dashboard}/>
          <ProtectedRoute isAllowed = {user}   path = '/canvas' component = {Canvas}/>
          {/* <Route  path = '/sandbox' component = {Sandbox}/> */}
        </Switch>
        
        </div>
    </BrowserRouter>
  )
}

const AppHeader = ({user,dispatch,strings}) => {
  let headerItems 
  if(user) {
    headerItems = (
      <div style = {{display:'flex'}}>
        <p onClick = {()=> dispatch(authActions.logout())} style = {{padding:'0 4px'}}>{strings.logout}</p>
        <p style = {{padding:'0 4px'}}>{user.username}</p>
      </div>
    )
  }
  else {
    headerItems = (
      <div style = {{display:'flex'}}>
        <p style = {{padding:'0 4px',margin:0}}><Link to ='/auth/login'>{strings.login}</Link></p>
        <p style = {{padding:'0 4px',margin:0}}><Link to ='/auth/signup'>{strings.createAccount}</Link></p>
      </div>
    )
  }
  return(
    <div >
  <Header>
         {headerItems}
  </Header>
 
    </div>
  
  )
}

const ProtectedRoute = ({ isAllowed, ...props }) => 
     isAllowed 
     ? <Route  {...props}/> 
     : <Redirect to="/landing"/>;
const stateToProps = state => ({user:state.auth.user,strings:state.language})
export default connect(stateToProps)(Router)