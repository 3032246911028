import React from 'react'
import TextArea from './text-area'
import Placeholder from '../components/placeholder'
class TextBlock extends  React.Component{
constructor(){
  super()
  this.state ={edit:false}
}
render() {
const {onEdit,label,value,placeholder} = this.props 
const {edit} = this.state
return(
  <div style = {{background:'var(--element-background-color)',padding:'8px 8px',boxShadow:'var(--shadow)',overflowWrap:'break-word'}} >
 
  
  
  
   <h3 dir = 'auto' style = {{color:'var(--primary-color)',margin:0}}>{label}</h3>
   {edit&&<TextArea placeholder = {placeholder} value = {value} focus = {true} onClick = { text => {
     this.setState({edit:false})
     onEdit(text)
   }}/> || (value&&<p dir = 'auto' style = {{overflowWrap:'break-word',whiteSpace: 'pre-wrap'}} onClick = {() => this.setState({edit:true})}>{value}</p>) || <Placeholder text = {placeholder} onClick = {() => this.setState({edit:true})}/>}
 {/* clean up later */}
  </div>
 
)
}
}
export default TextBlock