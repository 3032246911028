import React from 'react'
import {connect} from 'react-redux'
import TextInput from '../components/text-input'
import TextBlock from '../components/text-block'
import {canvseActions} from './canvas-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DateCard from '../components/date-card'
import RichEditor from '../components/editor'
import Todos from '../components/check-list'

import {faTimesCircle,faLightbulb,faCheckCircle,faAngleLeft,faThList} from '@fortawesome/free-solid-svg-icons'

import MainToolBar from '../components/main-tool-bar'
import Page from '../components/page'
import Editor from '../components/editor'


class CardInfo extends React.Component {
  constructor(props){
    super()
    this.state = {editName:false}
  }
  
  render(){
    const {match,canvas,history,dispatch,strings} = this.props
    const {id,blockName} = match.params
    const item = canvas.blocks[blockName].find(i => i.id === id)
    
    if(!item) {
      history.goBack()
      return (<div></div>)
    }
    
    const removeitem = () => {
      dispatch(canvseActions.removeItem(blockName,id))
      
    }
    const editItmeName = (id,name) => {
      dispatch(canvseActions.editItem(blockName,id,{name}))
      
    }

    const addTodoList = ()=> {
      dispatch(canvseActions.addTodoList(blockName,id))
    }

    const editTodoItem = (elemantId,todo,props)  => {
     // blockName,itemId,elemantId,todo,props
      dispatch(canvseActions.updateTodoItme(blockName,id,elemantId,todo,props))
    }
    const removeTodoItem = (elemantId,todoId) => {
      dispatch(canvseActions.removeTodoItem(blockName,id,elemantId,todoId))
    }
    const editElem = (elemant,props) => {

      dispatch(canvseActions.editElemant(blockName,id,elemant,props))
    }

    const removeElemant = (elemant) => {
      dispatch(canvseActions.removeElemant(blockName,id,elemant))
    }

    

    const editItmeDescription = (id,description) => {
      dispatch(canvseActions.editItem(blockName,id,{description}))
      this.setState({editNote:false})
    }
    const editItmeState = (id,state) =>  dispatch(canvseActions.editItem(blockName,id,{state}))
    const addTodo = (elmId,text) => {
      dispatch(canvseActions.addTodoItem(blockName,id,elmId,text))
    }
    console.log(item)
    return(
      <div>
        <MainToolBar backButton 
        center = {<Editor heading inline value = {item.name } onSubmit = {editItmeName.bind(null,id)} />}
        rigth = {item.createAt && <DateCard seconds = {item.createAt.seconds} lable = 'create at:' />}
       
        />
        <div style = {{background:'var(--header-color)',boxShadow:'var(--shadow)'}}>
    <div style = {{maxWidth:1440,margin:'auto',display:'flex',alignItems:'center',padding:16}}>
    
    <div style = {{flexGrow:2,textAlign:'center'}}>
    <div style = {{display:'grid',gridTemplateColumns:'auto auto auto',justifyItems:'center',fontSize:24}}>
        <FontAwesomeIcon  onClick = {editItmeState.bind(null,item.id,-1)} style = {{color: item.state === -1 ?'var(--secondary-color)':'inherit',cursor:'pointer'}} icon = {faTimesCircle}/>
        <FontAwesomeIcon onClick = {editItmeState.bind(null,item.id,0)} style = {{color: item.state === 0 ?'var(--secondary-color)':'inherit',cursor:'pointer'}} icon = {faLightbulb}/>
        <FontAwesomeIcon onClick = {editItmeState.bind(null,item.id,1)} style = {{color: item.state === 1 ?'var(--secondary-color)':'inherit',cursor:'pointer'}} icon = {faCheckCircle}/>
        </div>
    
    </div>
    <div style = {{flexGrow:1,textAlign:'center'}}>
    
     
    </div>
    <div style = {{display:'flex',flexGrow:1,textAlign:'center',alignItems:'center',justifyContent:'flex-end',fontSize:18}}>
    
    <FontAwesomeIcon style = {{cursor:'pointer',padding:4}}  onClick ={() => addTodoList()}  icon = {faThList}/>
    
    </div>
    
      
    </div>
      
  </div>
        
        <Page>
        <div >
        
        
        {/* <FontAwesomeIcon style = {{cursor:'pointer'}} size = '2x' onClick = {()=> history.goBack()} icon = {faAngleLeft}/>
        <div style = {{display:'flex',alignItems:'center',justifyContent:'center'}} >
        
        <RichEditor inline value = {item.name } onSubmit = {editItmeName.bind(null,id)} />
        </div> */}
        <div style = {{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
        
        </div>
        

        
        
        
        
         
        </div>
        
       
       
        
      
        <TextBlock label = {strings.description} placeholder = {strings.descriptionPlaceholder} value = {item.description} onEdit = {editItmeDescription.bind(null,id)} />
        <div className = 'gird-list'>
        {item.elemants && item.elemants.map((e,i)=> <Todos onRemoveElemant = {removeElemant.bind(null,e)} onEditList = {editElem.bind(null,e)}  onRemove = {removeTodoItem.bind(null,e.id)} onEdit = {editTodoItem.bind(null,e.id)} onAdd = {addTodo.bind(null,e.id)} todos = {e.items} title = {e.title} key = {i}/>)}
      </div>

      <div>
        <div style = {{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <button  style = {{padding:'12px 48px',
  background: 'red',
  color:'#fff',
  fontWeight:'bold',
  fontSize:18,
  borderRadius:18,
  border:'none',
  marginTop:32}} onClick ={removeitem} >{strings.remove}</button>
        </div>
      
      </div>
        
       
        </Page>
        
      
        
        
      </div>
    )
  }
}



const stateToProps = state => ({canvas:state.canvas,strings:state.language})

export default connect(stateToProps)(CardInfo)