import React from 'react'
import TextInput from './text-input'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faTimesCircle,faLightbulb,faCheckCircle,faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle,faCircle,faTimesCircle} from '@fortawesome/free-regular-svg-icons'
import Editor from './editor'

class Todo extends React.Component{
  constructor(){
    super()
    this.state = {editMode:false}
  }
  render(){
    const {item,onEdit,onRemove} = this.props
    const icon = item.check ? faCheckCircle : faCircle
    return(
      <div dir = 'auto' style = {{display:'flex',alignItems:'center',overflowWrap:'break-word',borderBottom:'1px solid rgba(0,0,0,.5)'}}>
      {/* <div style = {{width:16,height:16,borderRadius:'50%',border:'2px solid black',...checkStyle,marginRight:4}}/> */}
      <FontAwesomeIcon onClick = {()=> onEdit({check:!item.check})} icon = {icon} style = {{paddingRight:4,fontSize:18}}/>
      <div style = {{margin:0,textDecoration: item.check ?'line-through':'',flexGrow:1}}>
      <Editor  inline value = {item.text } onSubmit = { text => onEdit({text})} />
      </div>
     
       {/* <p style = {{margin:0,textDecoration: item.check ?'line-through':'',flexGrow:1}}>{item.text}</p> */}
      <p><FontAwesomeIcon onClick = {()=> onRemove()} icon = {faTimesCircle} style = {{fontSize:18}}/></p>
      </div>
    )
  }
}


const CheckList = ({todos,onAdd,onEdit,onRemove,title = 'untitled',onEditList,onRemoveElemant}) => {
  return(
    <div style = {{background:'#fff',boxShadow:'var(--shadow)',padding:8}}>
    <p style = {{margin:0,display:'flex',alignItems:'center',justifyContent:'flex-end'}}><FontAwesomeIcon onClick = {onRemoveElemant} icon = {faTimesCircle} style = {{fontSize:18}}/></p>
    <div style = {{textAlign:'center',fontWeight:'bold'}}>
    <Editor  inline value = {title } onSubmit = { title => onEditList({title})} />
    </div>
      {todos.map((t,i)=> <Todo onEdit = {onEdit.bind(null,t)} onRemove = {onRemove.bind(null,t.id)} key = {i} item = {t}/>)}
      <TextInput onEnter = {onAdd}/>
    </div>
  )
}

export default CheckList