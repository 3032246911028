import React from 'react'
import {connect} from 'react-redux'

import {toolBarActions} from '../tool-bar/tool-bar-redux'
import MainToolBar from '../components/main-tool-bar'
import Page from '../components/page'


class instructions extends React.Component {
  
  render(){
    const {match,canvas,history,dispatch,strings} = this.props
   
    const {blockName} = match.params
    
    return(
      <div>
        <MainToolBar backButton 
        center = {<h2 style = {{display:'content',textAlign:'center',margin:0}}>{strings.instruction}</h2>}
       
        />
        
        <Page>
        <h3 style = {{textAlign:'center'}}>{strings[blockName]}</h3>

<div style = {{maxWidth:960,margin:'auto'}} dir = 'auto'  dangerouslySetInnerHTML = {{__html:strings.instructions[blockName]}}/>
 
        </Page>
       
       
        
        
         
        </div>
    )
  }
}



const stateToProps = state => ({canvas:state.canvas,strings:state.language})

export default connect(stateToProps)(instructions)