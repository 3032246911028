import React from 'react'
import TextInput from './components/text-input'
import CheckList from './components/check-list'

class Sandbox extends React.Component {
  constructor(){
    super()
    this.state = {todos:[{text:'hi',id:1}]}
  }
  add(text){
    const {todos} = this.state
    const item = {text,id:todos.length}
    todos.push(item)
    this.setState({todos})
  }
  render() {
    const {todos} = this.state 
    console.log(todos)
    return(
      <div>
      <CheckList todos = {todos} onAdd = {this.add.bind(this)}/>
    </div>
    )
  }
}




export default Sandbox