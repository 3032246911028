import React from 'react'
import {connect} from 'react-redux'
import {canvseActions} from './canvas-redux'
import TextInput from '../components/text-input'
import Tag from '../components/tag'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle,faLightbulb,faCheckCircle,faPenSquare,faAngleLeft,faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import * as analysis from '../analysis'
import {toolBarActions} from '../tool-bar/tool-bar-redux'
import MainToolBar from '../components/main-tool-bar'
import Page from '../components/page'

const Card = ({item,onClick}) => {
  
  let icon =  faLightbulb
  if(item.state === 1) {
    icon =  faCheckCircle
  }
  else if (item.state === -1){
    icon = faTimesCircle
  }

  return(
    <div onClick = {onClick} style = {{cursor:'pointer',overflowWrap:'break-word',padding:8,background:'#fff',boxShadow:'var(--shadow)', borderRadius:10}}>
     <h3 dir = 'auto' style = {{margin:0,textAlign:'center',width:'100%'}}>{item.name} </h3>
     <div style = {{padding:'0 8px',display:'flex',alignItems:'center',opacity:.8,fontSize:14,margin:'8px 0'}}>
      <FontAwesomeIcon style = {{padding:'0 8px',margin:'0 4px'}} size = 'lg' icon = {icon} />
     
      {item.description &&<FontAwesomeIcon style = {{padding:'0 4px',margin:'0 4px'}}  icon = {faPenSquare}/>}
    </div> 
    </div>
  )
}

const Block = ({canvas,dispatch,history,match,strings}) => {
  
  
  const {blockName} = match.params
  const items = canvas.blocks[blockName]
  const addItem = name => name&&dispatch(canvseActions.addItem(blockName,name))
  
  const tools = (<FontAwesomeIcon  onClick = {() => {
    // analysis.track('view instructions',{block:blockName})
    history.push(`${blockName}/instructions`
  )}} style = {{fontSize:20,textAlign:'center',cursor:'pointer',color:'var(--primary-color)'}} size = 'sm'  icon = {faQuestionCircle} />)
  return(
    <div >
      <MainToolBar backButton 
        center = {<h2 style = {{textAlign:'center',margin:0}}>{strings[blockName]}</h2>}
        rigth = {tools}
        />
        
        <Page>
        <div style = {{maxWidth:512,margin:'auto'}}>
        
        
        <TextInput placeholder = {strings.addItemPlaceholder} onEnter = {addItem}/>
          </div>
       
        <div className = 'gird-list' >
          {items.map((item,i)=> <Card
           key = {i} item = {item} 
          onClick = { () =>history.push(`${blockName}/${item.id}`)}
           />)}
          
          
        </div>

        </Page>
     
        
    </div>
  )

}
const stateToProps = state => ({...state.block,canvas:state.canvas,strings:state.language})
export default connect(stateToProps)(Block)