import React from 'react'
import {connect} from 'react-redux'
import TextInput from '../components/text-input'
import {authActions} from './auth-redux'
import {Redirect} from 'react-router-dom'
import {db} from '../firebase-config'
import {makeVaildate,makeIsEmail,makeNotEmpty,makeMinLength,makeNotNumber,makeNoWhiteSpace} from '../util'



class Singup extends React.Component {
  constructor() {
    super()
    this.state = {
      inputs: {
        fullName:'',
        username:'',
        email:'',
        password:''
      
      
      }
     ,
      errors:{
      username:undefined,
      password:undefined,
      email:undefined,
      fullName:undefined
      },
      canSubmit:false
  }
  }
  componentDidMount() {
    const {dispatch} = this.props
    dispatch(authActions.resetErorrs())
  }
   async handelValidateError(fieldName,error) {
    const errors = this.state.errors
    errors[fieldName] = error 
    if(fieldName === 'username' && error.length === 0) {
      const {inputs} = this.state
      
      const e =   await this.checkUsername(inputs.username,'usernameTaken')
      if(e) {
        errors[fieldName].push(e)
       
      }
     
      
    }
    const canSubmit = Object.keys(errors).every(key => errors[key] && errors[key].length ===0 )
    this.setState({errors,canSubmit})
  }
  handlefieldChange(fieldName,value){
    const {inputs} = this.state
    inputs[fieldName] = value
    this.setState({inputs})
  }
  async checkUsername(username,message,) {
    const {strings} = this.props
   const snap = await db.collection('users').where('username','==',username).get()
   
    return snap.docs.length === 0 ? undefined : new Error(strings.errors[message])
    
   
  }
  submit(){
    const {dispatch} = this.props
    dispatch(authActions.singup(this.state.inputs))
  }
   render() {
    
   

  const {user,dispatch,history,authErrors,strings} = this.props
  

  
  if(user) {
    return (<Redirect to = '/'/>)
  }
  

  
  const usernameEmpty = makeNotEmpty(strings.username + strings.errors.emptyField)
  const usernameMinLeng = makeMinLength(strings.username + strings.errors.minFieldLength(3),3)
  const usernameNoSpace = makeNoWhiteSpace(strings.errors.whiteSpaceUsername)
  

  
  const passwordEmpty = makeNotEmpty(strings.password + strings.errors.emptyField)
  const passwordMinLeng = makeMinLength(strings.password + strings.errors.minFieldLength(6),6)

  const emailEmpty = makeNotEmpty(strings.email + strings.errors.emptyField)
  const unvalidEmail = makeIsEmail(strings.errors.unvalidEmail)

  const nameEmpty = makeNotEmpty(strings.fullName + strings.errors.emptyField)
  const nameMinLeng = makeMinLength(strings.fullName + strings.errors.minFieldLength(2),2)
  const nameNotNum = makeNotNumber(strings.errors.number)
  
  
  const validator = {
    username: makeVaildate(usernameEmpty,usernameMinLeng,usernameNoSpace),
    password:makeVaildate(passwordEmpty,passwordMinLeng),
    email:makeVaildate(emailEmpty,unvalidEmail),
    fullName:makeVaildate(nameEmpty,nameMinLeng,nameNotNum)
  }
  const {inputs,errors,canSubmit} = this.state
  return(
    
    <div style = {styles.component}>
    <h2 style = {{textAlign:'center',fontSize:20}}>{strings.createAccount}</h2>
    {authErrors.length >0 && authErrors.map((e,i)=><p dir = 'auto' key = {i}>{ strings.errors[e.message]}</p>)}
    <div style = {{display:'grid',rowGap:16}}>
    {Object.keys(inputs).map( (key,i) => <TextInput type = {key === 'password' ?'password' : 'text'} value = {inputs[key]} key = {i} validator = {validator[key]} onValidate = {this.handelValidateError.bind(this,key)} errors = {errors[key]} label = {strings[key] } placeholder = {strings[key]} onTextChange = {this.handlefieldChange.bind(this,key)}/>)}
    
    </div>
    <div style = {{display:'grid',rowGap:12}}>
    <button disabled = {!canSubmit} style = {{...styles.cta,opacity: canSubmit ? 1 : .5}} onClick = {this.submit.bind(this)} >{strings.createAccount}</button>
      <p onClick = {()=> history.push('/auth/login')} style = {styles.textButton}>{strings.login}</p>
      <p onClick = {()=> history.push('/auth/reset-password')} style = {styles.textButton}>{strings.resetPassword}</p>
    </div>
     
    </div>
    
  )
  }
}


const styles = {
component: {
  display:'flex',
  flexDirection:'column',
  maxWidth:400,
  margin:'auto'
},
cta:{
  padding:'12px 48px',
  background: 'var(--primary-color)',
  color:'#fff',
  fontWeight:'bold',
  fontSize:18,
  borderRadius:18,
  border:'none',
  marginTop:32
  },
  textButton: {
    textAlign:'center',
    color: 'var(--primary-color)',
    fontWeight:'bold',
    fontSize:14,
  }
}
const stateToProps = state => ({...state.auth,strings:state.language})
export default connect(stateToProps)(Singup)
