import React from 'react'


import {withRouter} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Editor from '../components/editor'



import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const MainToolBar = ({backButton= false,history,center,rigth}) => {
  
 
  
  return(
    <div style = {{background:'var(--header-color)',boxShadow:'var(--shadow)',padding:16}}>
    <div style = {{maxWidth:1440,margin:'auto',display:'flex',alignItems:'center'}}>
    
    <div>
    {backButton &&  <FontAwesomeIcon style = {{cursor:'pointer'}} size = '2x' onClick = {()=> history.goBack()} icon = {faAngleLeft}/>}
    </div>
    <div style = {{flexGrow:1,textAlign:'center'}}>
    
      {center}
    </div>
    <div>
      {rigth}
    </div>
    
      
    </div>
      
  </div>
  )
 
}


export default withRouter(MainToolBar)