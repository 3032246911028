import html2canvas from 'html2canvas'
import {db} from '../firebase-config'
import uuid from 'uuid/v1'
import * as analysis from '../analysis'



function genPDF(canvasName) {
  
  //  const doc = new jsPDF('landscape','pt','a2',true)
    const item = document.querySelector('#bmc').cloneNode(true)
    item.firstChild.classList.remove("canvas-view")
    item.firstChild.classList.add("canvas-exprot")
    item.style.width = '1440px'
    item.style.padding = '32px'
    const copy =document.createElement('h3')
    copy.style = 'text-align:center;'
    copy.textContent = 'powered by nawaa'
    item.appendChild(copy)
    const link = document.createElement('p')
    link.textContent =  'www.nawaa.app'
    link.style = 'text-align:center;'
    
    item.appendChild(link)
    document.body.appendChild(item)
    html2canvas(item,{scale:2,backgroundColor:'#f2f2f2'})
    .then(canvas => {
      const image =   canvas.toDataURL("image/jpeg")
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = image
    a.download = canvasName +'.jpeg'
    a.click()
    document.body.removeChild(a)
    document.body.removeChild(item )
  
    
    // doc.addImage(image, 'JPEG', 0, 0,594*2,420*2,'FAST')
    //   doc.save()
    })
}


const actionsType = {
  addItem:'add-item',
  removeItem:'remove-item',
  editItem:'edit-item',
  exportCanvas:'export-canvas',
  loadData:'load-data',
  loadDataSuccee:'load-data-succee',
  createDoc:'create-Doc',
  selectCanvas:'select-canvas',
  createCanvas:'create-canvas',
  updateCanvasProps:'updateCanvasProps',
  removeCanvas:'removeCanvas',
  addTodoItem:'addTodo',
  editTodoItem:'editTodo',
  removeTodoItem:'removeTodo',
  addElemant:'add-element',
  editElemant:'edit-element',
  removeElemant:'removeElemant'
}


const createBMCTemp = (name,user,workspace) => {
  return {
    name,
    type:'canvas',
    category:'bmc',
    user,
    workspace,
    createAt: new Date(),
    id:uuid(),
    blocks: {
      valueProposition:[],
      channels:[],
      customerSegments:[],
      customerRelationship:[],
      revenueStreams:[],
      keyResources:[],
      keyActivities:[],
      partners:[],
      costStructure:[]
    },
    members:[]
  }
}

const initState = null


export const canvseActions = {
  createDoc(user,workspace,fn = ()=>1){
    return dispatch => {
      
      const doc = createBMCTemp('my BMC',user,workspace)
      db.doc('docs/'+doc.id)
      .onSnapshot(snap => {
        if(snap.exists) {
          //dispatch({type:actionsType.loadDataSuccee,payload:{bmc:snap.data()}})
        }
        else {
          db.doc('docs/'+doc.id).set(doc)
          fn()
        }
        
      })  
    }
    
  },
  loadData(user){
    return dispatch => {
      dispatch({type:actionsType.loadData})
      db.collection('docs')
      .where('user','==',user)
      .get()
      .then(docs => {
       
        const doc = docs.docs[0]
        
          doc.ref.onSnapshot(snap => {
            
            dispatch({type:actionsType.loadDataSuccee,payload:{bmc:snap.data()}})
          
          })
       
      })
      
    }
  },
  addItem(blockName,text) {
    const date = new Date()
    const item = {name:text,id: uuid(),state:0,description:'',createAt: date,updateAt:date,elemants:[] }
    return {type:actionsType.addItem,payload:{blockName,item}}
  },
  removeItem(blockName,id){
    return {type:actionsType.removeItem,payload:{blockName,id}}
  },
  editItem(blockName,id,props){
    props.updateAt = new Date()
    return {type:actionsType.editItem,payload:{blockName,id,props}}
  },
  exportCanvas(canvasName){
    genPDF(canvasName)
    return {type:actionsType.exportCanvas}
  },
  createCanvas(user,workspace = null){
    return dispatch => {
      const canvas = createBMCTemp('untitled',user,workspace)
     
      dispatch(this.selectCanvas(canvas))
      db.doc('docs/'+canvas.id)
      .set(canvas)
      analysis.track('create doc',{type:'canvas',category:'bmc'})
      
    }
   
  },
  selectCanvas(canvas) {
    return dispatch => {
      dispatch({type:actionsType.selectCanvas,payload:{canvas}})
     
    }
    
  },
  updateCanvasProps(id,props) {
   
   return {type:actionsType.updateCanvasProps,payload:{id,props}}
  },
  removeCanvas(id){
    if (window.confirm('هل انت متاكد من حذف النموذج ')) {
      // Save it!
      return dispatch => {
        db.doc('docs/'+id)
        .delete()
        .then(()=> {
         dispatch({type:actionsType.removeCanvas,payload:{}})
        })
       }
  } else {
      // Do nothing!
      return {type:'cancel'}
  }
    
  },
  addTodoList(blockName,itemId) {
    const date = new Date()
    const elemant = {title:'untitled',id:uuid(),type:'checklist',createAt:date,updateAt:date,items:[]}
    return {type:actionsType.addElemant,payload:{blockName,itemId,elemant}}
  },
  addTodoItem(blockName,itemId,elemantId,text){
    console.log(elemantId,text)
    const date = new Date()
    const todo = {id:uuid(),createAt:date,updateAt:date,text,check:false}
    return {type:actionsType.addTodoItem,payload:{blockName,itemId,elemantId,todo}}

  },
  updateTodoItme(blockName,itemId,elemantId,todo,props) {
    const newTodo = {...todo,...props}
    console.log(props)
    return {type:actionsType.editTodoItem,payload:{blockName,itemId,elemantId,todo,newTodo}}
  },
  removeTodoItem(blockName,itemId,elemantId,todoId) {
    console.log(todoId)
     return {type:actionsType.removeTodoItem,payload:{blockName,itemId,elemantId,todoId}}
  },
  editElemant(blockName,itemId,elemant,props){
    const newElemant = {...elemant,...props}
    return {type:actionsType.editElemant,payload:{blockName,itemId,elemant,newElemant}}
  },
  removeElemant(blockName,itemId,elemant){
    return {type:actionsType.removeElemant,payload:{blockName,itemId,elemant}}
  }
  
}

const strategy = {
  [actionsType.loadDataSuccee]: (state,{bmc}) => {
    // this is hack to support old data the don't have work space
    // remove it before relese bata
    if(bmc.workspace === undefined) {
      bmc.workspace = null
    }
    return {...state,...bmc}
  },
  [actionsType.addItem]: (state,{blockName,item}) => {
    const blocks = {...state.blocks}
    blocks[blockName].push(item)
    const newSate = {...state,blocks}
    updateBlock(state.id,newSate)
    analysis.track('add block item',{'block':blockName})
    localStorage.setItem('BMC',JSON.stringify(newSate))
    return newSate
  },
  [actionsType.removeItem]: (state,{blockName,id})=> {
    const blocks = {...state.blocks}
    const item = blocks[blockName].find(i => i.id === id)
    const index = blocks[blockName].indexOf(item)
  
    blocks[blockName].splice(index,1)
    //  blocks[blockName] = blocks[blockName].filter(i => i.id !== id)
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
   // analysis.track('remove block item',{'block':blockName})
    
    return newSate
  },
  [actionsType.editItem]: (state,{blockName,id,props}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === id)
    const index = items.indexOf(item)
    items[index] = {...item,...props}
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     //analysis.track('edit block item',{'block':blockName,edit:Object.keys(props).filter(k => k !== 'updateAt')})
    
    return newSate
    
  },
  [actionsType.selectCanvas]: (state,{canvas}) => ({...canvas}),
  [actionsType.updateCanvasProps]: (state,{props,id}) => {
    if(state.id === id) {
     
      const newSate = {...state,...props}
      db.doc('docs/'+id)
  .update(newSate)
      return newSate
    }
    return state
  },
  [actionsType.removeCanvas]: state => {
    return null
  },
  [actionsType.addElemant]: (state,{blockName,itemId,elemant}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    if(item.elemants) {
      item.elemants.push(elemant)
    }
    else {
      item.elemants = [elemant]
    }
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate
  },
  [actionsType.addTodoItem]: (state,{blockName,itemId,elemantId,todo}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    const todoList = item.elemants.find(e => e.id === elemantId)
    
    todoList.items.push(todo)
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate
  },
  [actionsType.editTodoItem]: (state,{blockName,itemId,elemantId,todo,newTodo}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    const todoList = item.elemants.find(e => e.id === elemantId)
    console.log(todoList)
    const index = todoList.items.indexOf(todo)
    todoList.items[index] = newTodo
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate

  },
  [actionsType.removeTodoItem] :  (state,{blockName,itemId,elemantId,todoId}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    const todoList = item.elemants.find(e => e.id === elemantId)
    const todo = todoList.items.find(t => t.id === todoId)
    const index = todoList.items.indexOf(todo)
    todoList.items.splice(index,1)
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate
  },
  [actionsType.editElemant] :  (state,{blockName,itemId,elemant,newElemant}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    const index = item.elemants.indexOf(elemant)
    item.elemants[index] = newElemant
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate
  },
  [actionsType.removeElemant] :  (state,{blockName,itemId,elemant}) => {
    const blocks = {...state.blocks}
    const items = blocks[blockName]
    const item = items.find(i => i.id === itemId)
    const index = item.elemants.indexOf(elemant)
    item.elemants.splice(index,1)
    const newSate = {...state,blocks}
     updateBlock(state.id,newSate)
     return newSate
  }
}

function updateBlock(docId,doc){
  console.log(docId,doc)
  // /boards/L3HERpscDnSW4cGMUunK3qX7IhE2/docs/27TYe1545371909685
  db.doc('docs/'+docId)
  .update(doc)
}

export function canvasReducer(state = initState,action) {
return strategy[action.type] ? strategy[action.type](state,action.payload) : state
}