import React from 'react'
import {Route,Switch} from 'react-router-dom'
import Login from './login'
import Signup from './singup'
import ResetPassword from './reset-password'
import Page from '../components/page'



function Auth ({canvas,history,strings,dispatch}){
  return (
    <Page>
<Switch>
      <Route  exact path = '/auth' component = {Login}/>
      <Route  exact path = '/auth/login' component = {Login}/>
      <Route exact path = '/auth/signup' component = {Signup}/>
      <Route exact path = '/auth/reset-password' component = {ResetPassword}/>
      </Switch>
    </Page>
      
  )
}



export default Auth