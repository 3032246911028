import React from 'react'
const TextArea = ({focus = false,value = '',onChangeText,placeholder = 'type something',onEnter,onClick}) => {
  const input = {value}
  const handle = e => {
    input.value = e.target.value
    const lastChar = input.value[input.value .length -1]

    if(e.target.scrollTop !== 0) {
      e.target.style.height = e.target.scrollHeight + "px"
    }
   
    

    if(e.keyCode === 13 && onEnter && input.value.match(/\n\n\n$/)) {
      onEnter(input.value.trim())
      e.target.value = ''
      return
    }
    if(onChangeText) {
      onChangeText(input.value)
    }
  }
return(
  <div  >
  <div style  = {{display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid rgba(57,62,65,.5)'}}>
  <textarea  dir = 'auto' autoFocus = {focus} onFocus = {e => e.target.scrollIntoView()}  style = {{width:'100%',padding:'8px 16px',flexGrow:1,resize:'none',overflow: 'hidden'}}  defaultValue = {value} onKeyUp = {handle} placeholder = {placeholder}/>
  
  
  </div>
  <p style = {{display:'inline-block',padding:8,background:'var(--secondary-color)',color:'#fff'}} onClick = {() => onClick(input.value.trim())}>save</p>
  </div>
)
}
export default TextArea