import React, { Component } from 'react'
import {createStore,applyMiddleware,compose} from 'redux'
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import rootReducer from './root-reducer'
import Router from './router'
import {authActions} from './auth/auth-redux'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)))
 store.dispatch(authActions.onAuth())

class App extends Component {
  render() {
    return (
      <Provider store = {store}>
      <Router/>
      </Provider>
      
    )
  }
}

export default App
