
import React from 'react'

const Page = ({children}) => {
  return(
   <div style = {{padding:16}}>
<div style = {{maxWidth:1440,margin:'auto'}}>
{children}
</div>
    </div>
  )
}

export default Page