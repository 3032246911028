
import {customerSegmentsAR,customerRelationshipAr,channelsAr, valuePropositionAr, keyResourcesAr,keyActivitiesAr,partnersAr,revenueStreamsAr,costStructureAr} from './content'


const actionsType = {
  setLanguage:'set-language',
  unSppurtedLanguage:'unsppurted-language'
}
/*
@note language need to modify to support
mality docs type and pages
i think could somthing like this
- canves
  - bmc
  - lean
  - value
- map
  - coustmer map
- messages
- errors
- pages
*/
const language = {
  en: {
    canvas:'canvas',
    valueProposition: 'value proposition',
    channels:'channels',
    customerSegments:'customer segments',
    customerRelationship:'customer relationship',
    keyResources:'key resources',
    keyActivities:'key activities',
    partners:'partners',
    costStructure:'cost structure',
    revenueStreams:'revenue streams',
    addItemPlaceholder :'type something',
    descriptionPlaceholder:'add more detail',
    description:'Description',
    instructions: {
      customerSegments: 'customerSegments',
      valueProposition: 'value proposition',
      channels:'channels',
      customerRelationship:'customer relationship',
      keyResources:'key resources',
      keyActivities:'key activities',
      partners:'partners',
      costStructure:'cost structure',
      revenueStreams:'revenue streams',
    },
    instruction:'instructions',
    login:'login',
    createAccount:'create account',
    email:'email',
    password:'password',
    resetPassword:'reset password',
    fullName:'full name',
    username:'username',
    logout:'logout',
    export:'export',
    remove:'remove',
    errors: {
      emptyField:" can't be empty",
      minFieldLength: min => `must be ${min} ltter or more`,
      whiteSpaceUsername:'only use english latters and no whitespace',
      unvalidEmail:'this not valid email',
      number:"can't use numbers",
      wrongEmailOrPassword:'wrong email or password',
      emailAlreadyUsed:'email already used',
      usernameTaken:'username taken',
      emailNotFound:'this email not reigser'
    },
    messages:{
      sendPasswordReset:'we send rest link to you email'
    },
    landing:{
      titel:'your BMC in your pockit',
      cta:'get started'
    }
  },
  ar: {
    canvas:'الاطار',
    valueProposition: 'القيمه المضافه',
    channels:'القنوات',
    customerSegments:'شريحة العملاء',
    customerRelationship:'العلاقات مع العملاء',
    keyResources:'الموارد الرئيسيه',
    keyActivities:'الانشطه الرئيسيه',
    partners:'الشركاء',
    costStructure:'هيكل التكاليف',
    revenueStreams:'مصادر الايرادات',
    addItemPlaceholder :'اكتب',
    clickToAddItemsPlaceholder:'اضغط للاضافه',
    descriptionPlaceholder:'اضافه تفاصيل اكثر',
    description:'الوصف',
    instructions: {
      customerSegments: customerSegmentsAR,
    valueProposition: valuePropositionAr,
    channels:channelsAr,
    customerRelationship:customerRelationshipAr,
    keyResources:keyResourcesAr,
    keyActivities:keyActivitiesAr,
    partners:partnersAr,
    costStructure:costStructureAr,
    revenueStreams:revenueStreamsAr,
    },
    instruction:'الارشادات',
    login:'تسجيل دخول',
    createAccount:'انشاء حساب',
    email:'البريد الالكتروني',
    password:'كلمة المرور',
    resetPassword:'اعاده تعين كلمة المرور',
    fullName:'الاسم',
    username:'اسم المستخدم',
    logout:'خروج',
    export:'تصدير',
    remove:'حذف',
    errors: {
      emptyField:" لا يمكن ان يكون فارغ",
      minFieldLength: min => ` ${min} رموز او اكثر`,
      whiteSpaceUsername:'فقط الاحرف و الارقام الانجلزيه بودن اي مساحات خاليه',
      unvalidEmail:'ايمل غير صحيح',
      number:"لا يكن استخدام الارقام",
      wrongEmailOrPassword:'خطاء ايمل او الرقم السري',
      emailAlreadyUsed:'هذا الايمل مسجل مسبقا',
      usernameTaken:'اسم المستخدم مسجل مسبقا',
      emailNotFound:'هذا الايمل غير مسجل'
      
    },
    messages:{
      sendPasswordReset:'تم ارسل رابط اعاده التعين الى ايملك'
    },
    landing:{
      titel:'نموذج العمل معك اينما كنت',
      cta:'ابداء'
    }
  }
}
//language[localStorage.getItem('language')]  
const initState =  language.ar


export const languageActions = {
  setLanguage(code) {
    if(language[code]) {
      // localStorage.setItem('language',code)
      return {type:actionsType.setLanguage,payload:{code}}
    }
    return {type:actionsType.unSppurtedLanguage}
  },
  
}

const strategy = {
  [actionsType.setLanguage]: (state,{code}) => {
    return {...state,...language[code]}
  },
}

export function languageReducer(state = initState,action) {
return strategy[action.type] ? strategy[action.type](state,action.payload) : state
}