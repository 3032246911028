import React, { Component } from 'react'
import Logo from '../assits/logo.png'
const Header = ({children}) => {
  return(
    <div style = {{background:'var(--header-color)',zIndex:100,borderBottom:'1px solid #ddd',color:'var(--primary-color)',padding:12}}>
     <div style = {{maxWidth:1440,margin:'auto',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
     <div style = {{display:'flex',alignItems:'center'}}>
     <img src  = {Logo} width = '64px'/> 
     </div>
     
    <div style = {{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
      {children}
    </div>
     </div>
    
  </div>
  )
 
}

export default Header