import React from 'react'

const DateCard = ({seconds}) => {
  const date  = new Date(seconds * 1000)
  var options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
  return (
<div style = {{display:'flex',alignItems:'center',padding:'16px 0',justifyContent:'center'}}>

<p  style = {{margin:0,fontStyle:'italic',fontSize:12}}>{date.toLocaleDateString("ar-US",options)}</p>
</div>
  )

}
export default DateCard