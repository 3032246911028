import mixpanel from 'mixpanel-browser'

// 1b094c15af750508e0aeea7e3eebfeb5
//mixpanel.init("1b094c15af750508e0aeea7e3eebfeb5")
//  // Opt a user out of data collection


export function setNewUser({id,email,name,username}) {
  
  // mixpanel.people.set_once({
  //   '$email':email,
  //   '$created': new Date(),
  //   'username':username,
  //   name
  // })
  // mixpanel.alias(id)
  
  // mixpanel.track('create account')
}

export function userLogin(id) {
  
  // mixpanel.identify(id)
 
}

export function userLogout() {
 // mixpanel.opt_out_tracking()
}

export function track(eventName,props){
  
// mixpanel.track(eventName,props)
}
