import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Route,Switch,Redirect} from 'react-router-dom'
import {toolBarActions} from '../tool-bar/tool-bar-redux'
import {canvseActions} from './canvas-redux'
import Instructions from './instructions'
import Block from './block'
import CardInfo from './card-info'
import Overview from './overview'

class Canvas extends React.Component {
  
  componentDidMount(){
    const {dispatch,canvas,user} = this.props
    // dispatch(canvseActions.loadData(user.id))
    
    
   
  }
  render() {
    const {canvas,dispatch} = this.props
    if(!canvas) {
     return <Redirect to = '/'/>
    }
    if(canvas) {
      
    }
    // window.scrollTo(0, 0);
    return (
      <Switch>
      
      <Route exact  path = '/canvas' component = {Overview}/>
      <Route  exact path = '/canvas/:blockName/' component = {Block}/>
      <Route  exact path = '/canvas/:blockName/instructions' component = {Instructions}/>
      <Route exact path = '/canvas/:blockName/:id' component = {CardInfo}/>
      </Switch>
  )
  }
}


const stateToProps = state => ({canvas:state.canvas,strings:state.language,user:state.auth.user})

export default withRouter(connect(stateToProps)(Canvas))