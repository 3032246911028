import React from 'react'
import {connect} from 'react-redux'
import {languageActions} from '../localization'
import {canvseActions} from '../canvse/canvas-redux'
import {withRouter} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Editor from '../components/editor'




import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
const ToolBar = ({dispatch,title,user,location,canvas,strings,history}) => {
  
 const updateCanvasName = (id,name) => dispatch(canvseActions.updateCanvasProps(id,{name}))
  const showExport = location.pathname === '/canvas' || location.pathname === '/canvas/'
  const showAdd = location.pathname === '/'
  const createCanvas = () => {
    dispatch(canvseActions.createCanvas(user.id,user.workspaces[0].id))
    history.push('/canvas')
  }
  if(!user) {
    return <div></div>
  }
  const setLang = code => dispatch(languageActions.setLanguage(code))
  return(
    <div style = {{background:'var(--header-color)',boxShadow:'var(--shadow)',padding:16}}>
    <div style = {{maxWidth:1440,margin:'auto',display:'flex',alignItems:'center'}}>
    
    
    
     { <div > <FontAwesomeIcon style = {{cursor:'pointer'}} size = '2x' onClick = {()=> history.goBack()} icon = {faAngleLeft}/></div>}
     {!showExport && <h2 style = {{flexGrow:1,textAlign:'center',margin:0}}>{title}</h2>}
     {showExport && canvas && <div style = {{display:'flex',justifyContent:'center',alignItems:'center',flexGrow:1,height:36}}>
     <Editor inline value = {title} onSubmit = { updateCanvasName.bind(null,canvas.id)} />
     </div>}
   
     
     <div style = {{position:'relative'}}>
    
     { showAdd && <p style = {{position:'absolute',cursor:'pointer', top:-25,right:1,padding:8,margin:0,fontSize:24,fontWeight:'bold',color:'var(--primary-color)'}} onClick = {()=>  createCanvas()}>+</p>}
     </div>
    </div>
      
  </div>
  )
 
}

const stateToProps = state => ({...state.toolBar,user:state.auth.user,canvas:state.canvas,strings:state.language})
export default withRouter(connect(stateToProps)(ToolBar))